import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/style.css'
import './scss/bootstrap.scss';

import { BrowserRouter, Routes, Route } from "react-router-dom";


import Home from './Componet/Home';
import About from './Componet/About';
import Services from './Componet/Services';
import Menu from './Componet/Menu';
import Contact from './Componet/Contact';
import Testimonial from './Componet/Testimonial';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
      <Route exact path="/" element={<Home />}></Route>
      <Route exact path="/about" element={<About />}></Route>
      <Route exact path="/services" element={<Services/>}></Route>
      <Route exact path="/menu" element={<Menu/>}></Route>
      <Route exact path="/contect" element={<Contact/>}></Route>
      <Route exact path="/testimonial" element={<Testimonial/>}></Route>







      </Routes>
      
      </BrowserRouter>
      
    </div>
  );
}

export default App;
